<template>
  <!--
    The view for the Terminal sales transactions
  -->
  <div class="installationDetail_TerminalSales">
    <template>
      <div class="card">
        <Portlet
          :title="$t('terminalForm.salesTransactions')"
          icon="receipt"
        >
          <template slot="buttons">
            <div
              v-if="balanceUrl"
              class="row mr-3 mt-3"
            >
              <p>Vending Balance:</p>
              <a
                :href="balanceUrl"
                target="_blank"
                class="ml-2"
                style="color: rgb(101, 101, 101)"
              >
                <font-awesome-icon
                  size="lg"
                  icon="receipt"
                />
              </a>
            </div>
            <date-picker
              v-model="date"
              :editable="true"
              :clearable="false"
              type="date"
              lang="en"
              format="DD.MM.YYYY"
              value-type="date"
              :first-day-of-week="1"
              class="mr-3"
            />
            <p class="mt-3 mr-3">
              {{ refreshDate }}
            </p>
            <font-awesome-icon
              :class="['alt-pointer color-primary mr-2', { 'fa-spin' : loading}]"
              icon="sync-alt"
              @click="getTransactions()"
            />
          </template>
          <SalesTransactionList
            ref="list"
            :installation-id="installationId"
            :date="date"
            @stopLoading="sLoading"
            @setBalanceUrl="setBalanceUrl"
          />
        </Portlet>
      </div>
    </template>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';

export default {
  name: "InstallationDetailTerminalSales",
  components: {
    SalesTransactionList: () => import('@/components/Terminal/SalesTransactionList.vue'),
    DatePicker
  },
  props: {
    installationId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      refreshSales: null,
      refreshDate: null,
      date: new Date(),
      balanceUrl: null
    };
  },
  metaInfo () {
    return {
      title: this.$t('terminalForm.salesTransactions')
    };
  },
  beforeMount () {
      this.refreshSales = window.setInterval(() => {
        this.getTransactions();
      }, 60000 * 3);
    },
    beforeDestroy () {
      window.clearInterval(this.refreshSales);
    },
  methods: {
    setBalanceUrl (val) {
      this.balanceUrl = val;
    },
    sLoading (date) {
      this.refreshDate = date;
      this.loading = false;
    },
    getTransactions () {
      this.loading = true;
      this.$refs.list.getSalesTransactions();
    }
  }
};
</script>